<template>
  <div id="DepositoCadastro" class="py-3 px-0">
    <!-- Cabeçalho - Titulo / Botão Editar-->
    <v-toolbar-title
      v-bind:style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold font-weight-medium body-1"
        v-bind:style="{ color: COR_SUBTITULO }"
      >
        Dados Básicos
      </p>
      <v-btn 
        v-if="store_Deposito.acao == 'C'"
        @click="
          store_Deposito.acao = 'E';
          deposito_edicao = {
            ...store_Deposito.deposito_edicao,
          };
        "
        class="btn mr-4 pr-6"
        style="background-color: #dce1eb"
        elevation="0"
      >
        <v-icon class="btn-icon mr-1" color="light-blue accent-2 "
          >mdi-view-headline</v-icon
        >
        <span
          class="caption font-weight-medium"
          v-bind:style="{ color: COR_SUBTITULO }"
          >Editar</span
        >
      </v-btn>
    </v-toolbar-title>

    <v-dialog
      v-model="store_Deposito.dialogDadosBasicos"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
    </v-dialog>
    <!-- Formulario Cadastro/Edição ----------------------------------------------------------->
    <v-form v-model="valid" id="form" ref="form" class="mx-0 mt-3">
      <v-container class="mt-2 d-flex flex-column">
        <!-- 1ª Linha - Tipo do Deposito / Nome / -->
        <v-row no-gutter class="pt-0 mb-n10">
          <!-- Nome Deposito -->
          <v-col md="6">
            <v-text-field
              v-model="store_Deposito.deposito_selecionado.nome_deposito"
              class="mb-0"
              maxlength="100"
              loader-height="1"
              label="Nome Deposito"
              hide-details="auto"
              placeholder="Nome Deposito"
              background-color="#FFF"
              light
              filled
              outlined
              dense
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>
          </v-col>

          <!-- Quadra -->
          <v-col md="2">
            <v-text-field
              v-model="store_Deposito.deposito_selecionado.quadra"
              class="mb-0"
              light
              background-color="#FFF"
              item-text="quadra"
              item-value="quadra"
              label="Quadra"
              placeholder="Quadra"
              maxlength="30"
              filled
              outlined
              required
              dense
              autofocus
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>
          </v-col>

          <!-- Lote -->
          <v-col md="2">
            <v-text-field
              v-model="store_Deposito.deposito_selecionado.lote"
              class="mb-0"
              light
              background-color="#FFF"
              item-text="lote"
              item-value="lote"
              label="Lote"
              placeholder="Lote"
              maxlength="30"
              filled
              outlined
              required
              dense
              autofocus
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>
          </v-col>

          <!-- Cep -->
          <v-col md="2">
            <v-text-field
              v-model="store_Deposito.deposito_selecionado.cep"
              class="mb-0"
              light
              background-color="#FFF"
              label="Cep"
              placeholder="xxxxx-xxx"
              maxlength="9"
              filled
              outlined
              required
              dense
              autofocus
              :readonly="store_Deposito.acao == 'C'"
              v-mask="MASK_CEP"
              :rules="cepRules"
            >
              <!-- Botão Consulta CEP -->
              <template v-slot:append-outer>
                <v-icon
                  class="btn-icon-plus mt-1"
                  color="accent-4"
                  @click="buscaEndereco(store_Deposito.deposito_selecionado.cep)"
                >
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
            </v-col>   
        </v-row>

        <!-- 2ª Linha - Logradouro / Bairro -->
        <v-row no-gutter class="pt-0 mb-n10 ">
          <!-- Logradouro -->
          <v-col md="4">
            <v-text-field
              v-model="store_Deposito.deposito_selecionado.logradouro"
              item-text="logradouro"
              item-value="logradouro"
              label="Logradouro"
              placeholder="Logradouro"
              loader-height="1"
              background-color="#FFF"
              maxlength="100"
              light
              filled
              outlined
              dense
              :menu-props="{ maxHeight: '250px' }"
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>
          </v-col>

          <!-- Bairro -->
          <v-col md="4">
            <v-text-field
              v-model="store_Deposito.deposito_selecionado.bairro"
              item-text="bairro"
              item-value="bairro"
              label="Bairro"
              placeholder="Bairro"
              loader-height="1"
              background-color="#FFF"
              maxlength="100"
              light
              filled
              outlined
              dense
              :menu-props="{ maxHeight: '250px' }"
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>  
          </v-col>

          <!-- Valor UF -->
          <v-col md="2">
            <v-text-field
              item-text="uf"
              item-value="uf"
              label="UF"
              placeholder="UF"
              dense
              v-model="store_Deposito.deposito_selecionado.uf"
              cache-items
              flat
              outlined
              filled
              background-color="#FFF"
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>  
          </v-col>

          <!-- Cidade -->
          <v-col md="2">
            <v-text-field
              item-text="cidade"
              item-value="cidade"
              label="Cidade"
              placeholder="Cidade"
              dense
              v-model="store_Deposito.deposito_selecionado.cidade"
              cache-items
              flat
              outlined
              filled
              background-color="#FFF"
              :readonly="store_Deposito.acao == 'C'"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <!-- 3ª Linha - Complemento / Número -->
        <v-row no-gutter class="pt-0 mb-n7">
          <!-- Complemento -->
          <v-col md="3">
            <v-text-field
             item-text="complemento"
              item-value="complemento"
              label="Complemento"
              placeholder="Complemento"
              dense
              v-model="store_Deposito.deposito_selecionado.complemento"
              cache-items
              flat
              outlined
              filled
              background-color="#FFF"
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>
          </v-col>
          <!-- Numero -->
          <v-col md="3">
            <v-text-field
              item-text="numero"
              item-value="numero"
              label="Número"
              placeholder="Número"
              dense
              v-model="store_Deposito.deposito_selecionado.numero"
              cache-items
              flat
              outlined
              filled
              background-color="#FFF"
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>
          </v-col>
          
          <!-- Tipo -->
          <v-col md="3">
            <v-text-field
              item-text="tipo"
              item-value="tipo"
              label="Tipo"
              placeholder="Tipo"
              dense
              v-model="store_Deposito.deposito_selecionado.tipo"
              cache-items
              flat
              outlined
              filled
              background-color="#FFF"
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>
          </v-col>

          <!-- Situação -->
          <v-col md="3">
            <v-text-field
              item-text="situacao"
              item-value="situacao"
              label="Situação"
              placeholder="Situação"
              dense
              v-model="store_Deposito.deposito_selecionado.situacao"
              cache-items
              flat
              outlined
              filled
              background-color="#FFF"
              :readonly="store_Deposito.acao == 'C'"
            ></v-text-field>
          </v-col>
        </v-row>

      </v-container>

      <!-- Rodapé - Botões Salvar / Cancelar -->
      <v-footer
        v-if="store_Deposito.acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center mt-3 pt-3 pb-n3"
        fixed
        style="position: static"
      >
        <v-btn
          v-if="store_Deposito.acao == 'E'"
          @click="cancelar()"
          cer
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text
        >
          Cancelar
        </v-btn>
        <router-link
          v-else-if="store_Deposito.acao == 'I'"
          :to="{ name: 'DepositoConsulta' }"
          class="nav-link"
          aria-current="page"
        >
          <v-btn
            cer
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4 caption font-weight-medium"
            color="primary"
            text
          >
            Cancelar
          </v-btn>
        </router-link>

        <v-btn
          :loading="loading"
          :disabled="!valid"
          @click="CadastraAtualizaDeposito()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-form>

    <!-- Dialog Confirmação de Exclusão -->
    <v-container>
      <v-dialog v-model="dialogCancelar" transition="dialog-bottom-transition" max-width="410" class="pa-0">
        <v-card elevation="0" class="">
          <v-card-title class="text-h6">
            Deseja realmente Excluir este Registro?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialogCancelar = false">
              Cancelar
            </v-btn>
            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="ExcluiEndereco()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import store_site from "./../../../store/store_site";
import store_Deposito from "./store_Deposito";
import store_ModalNegociacao from '../../ModalNegociacao/store_ModalNegociacao'
import ModalSelecaoPessoa from "../../ModalNegociacao/ModalSelecaoPessoa.vue";
import { API } from "../../../services/API";
import axios from "axios";

import {
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";

import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
  MASK_CEP,
} from "../../../services/constantes";

import { computed } from 'vue';

export default {
  name: "DepositoCadastro",

  components: {
  },

  data() {
    return {
      store_site      : store_site,
      store_Deposito    : store_Deposito,

      MASK_CEP          : MASK_CEP,
      COR_PRINCIPAL   : COR_PRINCIPAL,
      COR_SUBTITULO   : COR_SUBTITULO,
      COR_SECUNDARIA  : COR_SECUNDARIA,
      formatDate      : formatDate,
      nomeIniciais    : nomeIniciais,

      array_cep         : {},
      valid           : true,
      alert           : false,
      alert_msg       : "",
      loading         : false,

      dialog_crop: false,
      dialogCancelar: false,

      
      // Opcões de Selects estaticos
      opcoes: [{title: "Editar"},{title: "Excluir"}],
      situacao: ["Finalizada", "Em Andamento", "Parada"],
      
      cepRules: [
        (value) => !!value || "O cep é obrigatório",  
        (value) => (value && value.length == 9) || "O cep não é válido",
      ],
    };
  },

  watch: {
    "store_Deposito.deposito_edicao"(val) {
      this.atualizaAbas();
    },
  },

  async mounted() {
    console.log("Dados",store_Deposito.deposito_edicao) 
  },

  methods: {
    async atualizaAbas() {
      if (
        !!this.store_Deposito.deposito_edicao?.cod_deposito &&
        this.store_Deposito.deposito_edicao?.menu_abas
      ) {
        let abas = await this.store_Deposito.menuAbas();
        this.store_Deposito.abas = abas;
      }
    },
  
    async CadastraAtualizaDeposito() {
      const lb_valido = this.$refs.form.validate();
      if(lb_valido) {
        let ls_Rel = null;
        var lo_params = {...JSON.parse(JSON.stringify(this.store_Deposito.deposito_edicao))};
        console.log('Dados enviados:', lo_params);
        if (this.store_Deposito.acao == "I") {
          ls_Rel = await this.store_Deposito.DepositoPost(lo_params);
          this.store_Deposito.deposito_edicao.cod_deposito = ls_Rel.data.result.data.cod_deposito;
           console.log('Dados atualizados:', ls_Rel);
        }
        else if (this.store_Deposito.acao == "E") {
          ls_Rel = await this.store_Deposito.DepositoPut(lo_params);
        }
        if (ls_Rel.status == 200 ) {
          this.store_Deposito.deposito_edicao.cod_deposito = ls_Rel.data.result.data.cod_deposito;
          this.store_Deposito.acao = "C";
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Rel.data.result.result;
          this.store_site.alert = true;
        }
        else {
          this.store_site.alert_cor = "#FF0000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Rel.data.result;
          this.store_site.alert = true;
        }
      }
    },

    cancelar() {
      if (this.store_Deposito.acao == "I") {
        this.deposito_edicao = null;
      } else if (this.store_Deposito.acao == "E") {
        this.store_Deposito.deposito_edicao = {
          ...this.deposito_edicao,
        };
      }
      this.store_Deposito.acao = "C";
    },
    ModalConfirmacao(item){
      this.dialogCancelar = true;
    },
    fecha_alert() {
      this.alert = false;
    },
      // Busca CEP
    async buscaEndereco(val) {
      console.log("buscaEndereco chamado com valor:", val);
      if (val && val.length == 9) {
        const ls_Rel_Cep = await axios.get(
          
          `https://viacep.com.br/ws/${val}/json/`
        );
         console.log("ls_Rel_Cep", ls_Rel_Cep); // Adicionei essa linha
        if (!ls_Rel_Cep.data.erro) {
          this.array_cep = ls_Rel_Cep.data;
          this.store_Deposito.deposito_edicao.logradouro = this.array_cep.logradouro;
          this.store_Deposito.deposito_edicao.bairro = this.array_cep.bairro;
          thus.store_Deposito.deposito_edicao.cidade = this.array_cep.localidade;

          //const ls_Rel_Municipios = await API.get(`municipio`, {params: { cod_ibge: this.array_cep.ibge }});

          //if (ls_Rel_Municipios.data.result.length > 0) {
            this.arrayMunicipios = [ { codigo         : this.array_cep.ibge,
                                        nome          : this.array_cep.localidade,
                                        uf            : this.array_cep.uf, 
                                        nome_exibicao : this.array_cep.localidade + ' - ' + this.array_cep.uf}]
            this.enderecoObraSelecionado = {...this.arrayMunicipios[0]};

          //}
      }
    }
  },

  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
};
</script>

<style scoped>
#DepositoCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media (max-width: 599px) {
  #DepositoCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#DepositoCadastro::-webkit-scrollbar {
  width: 5px;
}

#DepositoCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#DepositoCadastro::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#DepositoCadastro::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #DepositoCadastro::-webkit-scrollbar {
    width: initial;
  }

  #DepositoCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #DepositoCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #DepositoCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px !important;
  color: #9e9e9e;
}

.flex-column {
  flex-direction: column !important;
}

@media (max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px !important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge,
  .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA) !important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px !important;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  color: #ff0000;
}
</style>
