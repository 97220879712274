<template>
  <div id="DepositoEstoque" class="py-3 px-0">
    <v-toolbar-title
      :style="{ color: COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-4"
    >
      <p
        class="font-primary font-weight-bold body-1"
        :style="{ color: COR_SUBTITULO }"
      >
        Estoque
      </p>        
    </v-toolbar-title>
      <!-- Formulario Cadastro/Edição ----------------------------------------------------------->
    <v-container class="d-flex flex-column">      
      <!-- 1ª Linha - Tabela Historico da Obra -->
      <v-row class="mt-n10 mx-0">
       <!-- Divisor -->
        <hr class="my-4" style="opacity: 0">
        <!-- <v-container
          class="mb-n3 d-flex justify-center"
          style="background-color: #fff; border-radius: 3px; border-bottom: 1px solid #E9E9E9;" 
        >
          <v-row class="mt-n5" align="center" justify="space-between">
              <v-col class="d-flex justify-start">
                <h3
                :style="{ color: COR_SUBTITULO }"
                > 1º Armazem</h3>
              </v-col>
            <v-col class="d-flex justify-end">
              <v-btn
              elevation="0"
              color="#fff"
              @click="TransferirInsumo()"
              >
                <v-icon
                  :style="{color: COR_SUBTITULO}"
                >
                  mdi-swap-horizontal
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container> -->
          <!-- 1ª tabela -->
          <v-col cols="12" class="pa-0 mx-0">
            <v-container
              class="container-principal container-rounded rounded-lg mx-0 px-0"
            >
              <!-- Conteiner tabela -------------------------------------- -->
              <v-data-table
          id="virtualScrollTable"
          ref="virtualScrollTable"
          :items="insumos"
          :headers="headers"
          :loading="loading"
          :items-per-page="20"
          :hide-default-footer="true"
          :hide-default-header="isMobile"
          :page="currentPage || 1"
          :server-items-length="store_Deposito.count_dados" 
          fixed-header
          dense
          style="white-space: nowrap"
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
          >
            <!-- @update:page="$vuetify.goTo($refs.virtualScrollTable)" -->
            <template #item="{ item }">
              <tr v-if="!isMobile">

                <!-- Campo dt_movimentacao -->
                <td style="width: 20%">
                  {{ item.dt_movimentacao }}
                </td>

                <!-- Campo Usuario -->
                <td style="width: 20%">
                  {{ item.usuario }}
                </td>

                 <!-- Campo acao-->
                <td style="width: 20%">
                  <span
                   class="d-flex align-center mb-1"
                   style="font-size: 14px; font-weight: bold"
                   :style="{ color: COR_SUBTITULO }"
                  >
                    {{ item.acao === 'E'  ? 'Entrada' 
                     : item.acao === 'B'  ? 'Baixa' 
                     : item.acao === 'T'  ? 'Transferência' : 'Nenhum'}}
                  </span>
                  <span
                    style="font-size: 12px; color: #808080 !important"
                    class="mb-1"
                  >
                  <span style="font-weight: bold">Motivo: </span>
                    {{ item.motivo }}
                  </span>
                </td>

                 <!-- Campo quantidade -->
                <td style="width: 20%">
                  {{ item.quantidade }}
                </td>

                 <!-- Campo valor unitario -->
                <td style=" width: 20%">
                  {{ item.valor_unitario ? `R$ ${formatNumber(item.valor_unitario)}` : "" }}
                </td>

            </tr>

              <!-- MOBILE -->
              <tr v-if="isMobile">
                <td style="width: auto">
                  <div class="mt-1" style="font-size: 14px; font-weight: 500">
                    {{ item.pessoa_nome }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.pessoa_email }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.fone_celular1 }}
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          </v-container>
        </v-col>
      </v-row>

      
    </v-container>
  </div>
</template>

<!-- Fim tabela --------------------------------- -->

<script>

import store_Deposito from "./store_Deposito";
import store_site from "./../../../store/store_site";

import {
  formatNumber,
  formatDate,
  nomeIniciais,
} from "../../../services/funcoes";

import {
  COR_PRINCIPAL,
  COR_SUBTITULO,
  COR_SECUNDARIA,
} from "../../../services/constantes";

export default {
  name: "DepositoMovimentacao",
  components: {
  },
  


  data() {
    return {
      store_site               : store_site,
      store_Deposito           : store_Deposito,

      COR_PRINCIPAL            : COR_PRINCIPAL,
      COR_SUBTITULO            : COR_SUBTITULO,
      COR_SECUNDARIA           : COR_SECUNDARIA,
      formatNumber             : formatNumber,
      formatDate               : formatDate,
      nomeIniciais             : nomeIniciais,
      dialogCancelar           : false,
      dialog_excluir           : false,
      dialog_excluir_alert     : false,
      dialog_excluir_msg       : false,
      dialog_excluir_message   : null,
      dialogIndiceCadastro     : false,
      opcoes_situacao          : null,
      count_dados              : null,
      paginas                  : null,    

      insumos                  : [],

      currentPage              : 1,
      loading_excluir          : false,
      filtro                   : null,
      valid                    : true,
      alert                    : false,
      alert_msg                : "",
      loading                  : false,
      deposito_selecionadoAux  : null, // Dados a serem alterados da obra selecionada

      // Cabeçalho da Tabela de historicos
      "headers": [
        { text: "Data", value: "dt_movimentacao", sortable: true, },
        { text: "Usuario", value: "usuario", sortable: true,  },
        { text: "Ação", value: "acao", sortable: true, },
        { text: "Quantidade", value: "quantidade", sortable: true,},
        { text: "Valor Unitario", value: "valor_unitario", sortable: true, },
      ],
    
      
    };
    
  },

  watch: {
    "store_Deposito.deposito_selecionado"(val) {
      this.atualizaAbas();
    },
  },
  
  async created() { 
  }, 
  async mounted() {
    store_Deposito.InsumosGet().then(response => {
      console.log("Resposta do servidor:", response);
      this.insumos = response;
      console.log("insumos", this.insumos);
    });
  
  },
  watch: {
    search(val) {
      // console.log('WATCH', val)
      this.currentPage = 1;
    },
  },

  methods: {
    async atualizaAbas() {
      if (
        !!this.store_Deposito.deposito_selecionado?.cod_obra &&
        this.store_Deposito.deposito_selecionado?.menu_abas
      ) {
        let abas = await this.store_Deposito.menuAbas();
        this.store_Deposito.abas = abas;
      }
    },


    fecha_alert() {
      this.alert = false;
    },

    updateValorPrevisto(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Deposito.deposito_selecionado.valor_previsto = numericValue;
    },
    
    updateValorRealizado(newValue) {
      // Remove a formatação para salvar o valor numérico
      const numericValue = Number(newValue.replace(/\D/g, "")) / 100;
      this.store_Deposito.deposito_selecionado.valor_realizado = numericValue;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    formattedValorPrevisto() {
      // Formata o valor para exibição
      let valor = this.store_Deposito.deposito_selecionado.valor_previsto;
      return formatNumber(valor);
    },
    formattedValorRealizado() {
      // Formata o valor para exibição
      let valor = this.store_Deposito.deposito_selecionado.valor_realizado;
      return formatNumber(valor);
    },
  },
};
</script>

<style scoped>
@media (max-width: 599px) {
  #InsumoEstoque {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#InsumoEstoque::-webkit-scrollbar {
  width: 5px;
}

#InsumoEstoque::-webkit-scrollbar-button {
  padding: 1px;
}

#InsumoEstoque::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

#InsumoEstoque::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
  height: 150px;
}

@media (max-width: 599px) {
  #InsumoEstoque::-webkit-scrollbar {
    width: initial;
  }

  #InsumoEstoque::-webkit-scrollbar-button {
    padding: initial;
  }

  #InsumoEstoque::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #InsumoEstoque::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px !important;
  color: #9e9e9e;
}

.flex-column {
  flex-direction: column !important;
}

@media (max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px !important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge,
  .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA) !important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px !important;
}
